<template>
  <div>
    <div class="air__utils__heading">
      <h5 v-if="user !== false">Perfil de {{user.name}}</h5>
      <h5 v-else>Nuevo Usuario</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>

      <!--      Info Principal -->
      <div class="card p-4">
        <a-form-model
          class="mb-1"
          ref="rule-Main"
          :model="mainForm"
          :rules="mainFormRules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="name"  prop="name" label="Nombre">
            <a-input v-model.trim="mainForm.name" size="large" placeholder="Nombre del Usuario"  :disabled="user !== false"/>
          </a-form-model-item>
          <a-form-model-item ref="email"  prop="email" label="Email">
            <a-input v-model.trim="mainForm.email" size="large" placeholder="Correo Electrónico" :disabled="user !== false"/>
          </a-form-model-item>

          <my-spinner :load="loading"/>

          <div class="form-actions mt-0" >
            <a-col span="4" offset="11">
              <responsive-button @ClickEvent="onSubmit" pill responsive="md" text="Guardar" fontAwesome="fa fa-save" variant="primary" v-show="!loading && email ==='0'"/>
              <responsive-button @ClickEvent="onDelete" pill responsive="md" text="Eliminar" icon="trash" variant="danger" v-show="!loading && user !== false"/>
            </a-col>
          </div>
        </a-form-model>
      </div>
      <!--      Info Principal -->
    <div class="row justify-content-center" v-if="email !== '0'">
      <div class="card pt-5">
        <div class="card-header card-header-flex flex-column">
          <a-tabs default-active-key="1" size="small" type="card" >
            <a-tab-pane key="1" >
                <span slot="tab">
                  <a-icon type="security-scan" />
                  Roles y Permisos
                </span>
              <div class="card-body my-3">
                <!--      Roles Y Permisos -->
                <user-roles-and-permissions  :emailUser="email"/>
                <!--      Roles Y Permisos -->
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" >
                <span slot="tab">
                  <a-icon type="solution" />
                  Información de Empleado
                </span>
              <div class="card-body">
                <!--  EmployeeSettings -->
                <employeeSettingsUser :emailUser="email"/>
                <!--  EmployeeSettings -->
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/axios/index'
import userRolesAndPermissions from '../components/userRolesAndPermissions'
import employeeSettingsUser from '../components/employeeSettingsUser'
import { Modal, notification } from 'ant-design-vue'
export default {
  name: 'registerEdit',
  components: {
    userRolesAndPermissions,
    employeeSettingsUser,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'admin_users',
          breadcrumbName: 'Administración de Usuarios',
          aIcon: '',
        },
        {
          breadcrumbName: 'Perfil de Usuario',
          aIcon: '',
        },
      ],
      email: this.$route.params.user,
      user: false,
      loading: false,
      mainForm: {
        name: '',
        email: '',
      },
      mainFormRules: {
        name: [
          { required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'El Nombre no es válido.', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'El Correo Electrónico es obligatorio.', trigger: 'blur' },
          { type: 'email', message: 'El Correo Electrónico no es válido.', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    getUserInfo() {
      api.get(`/user/${this.email}`)
        .then(response => {
          // Get the role and permission info
          const user = response.data.user
          this.user = user
          this.mainForm.email = this.email
          this.mainForm.name = user.name
        })
        .catch(() => {
          this.user = false
        })
    },
    onSubmit() {
      this.$refs['rule-Main'].validate(valid => {
        if (valid) {
          this.loading = true
          api.post('/user', this.mainForm)
            .then(response => {
              // Get the role and permission info
              const user = response.data.user
              this.user = user
              this.email = user.email
              this.$router.push({ name: 'detail_user', params: { user: user.email } })
              this.loading = false
            })
            .catch(() => {
              this.user = false
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    onDelete() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de eliminar este usuario?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        onOk() {
          // Delete Object
          const deleteObject = {
            jsonApi: {
              type: 'users',
              id: me.user.id,
            },
          }
          // Delete Object
          me.$store.dispatch('jv/delete', [deleteObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro eliminado exitosamente',
              })
              me.$router.push({ name: 'admin_users' })
              me.loading = false
            })
            .catch(() => {
              me.loading = false
            })
        },
        onCancel() {
          notification.info({
            message: 'El usuario NO ha sido eliminado',
          })
        },
      })
    },
  },
  mounted() {
    // console.log('mounted registerEdit', this.email)
    if (this.email !== '0') {
      this.getUserInfo()
    }
  },
}
</script>

<style scoped>

</style>
